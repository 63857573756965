import { NotificationForm } from "./NotificationForm"
import { useState } from 'react';

export const Notifications = ()=>{
    const [showNotificationForm, setShowNotificationForm] = useState(false);
    const openNotificationForm = ()=>{
        setShowNotificationForm(true)
    }

    const closeNotificationForm = ()=>{
        setShowNotificationForm(false)
    }

    return(
        <div>
            <h1>Notificaciones</h1>

            <button onClick={openNotificationForm}>Nueva</button>
            
            {showNotificationForm &&
                <NotificationForm close={closeNotificationForm}/>
            }
        </div>
    )
}