import { useEffect, useState } from 'react';
import { artistsService } from '../../_services/artists.service';

export const Artists = ()=>{
    const [artists, setArtists] = useState([]);
    useEffect(()=>{
        artistsService.get()
        .then((data)=>{
        setArtists(data);
        })
    },[]);
    return (
        <div>
        <h1>Artistas</h1>
        <div>
        <table className="table">
            <thead>
                <tr>
                    <th>
                        ID
                    </th>
                    <th>
                        Nombre
                    </th>
                    <th>
                        Subscripciones
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
            </thead>
            <tbody>
                {artists.map((artist,index)=>
                    <tr key={artist.id}>
                        <td>{artist.id}</td>
                        <td>{artist.name}</td>
                        <td>{artist.subscriptionsCount}</td>
                        <td><button >Modificar</button></td>
                    </tr>
                    )
                }
            </tbody>
        </table>
        </div>

      </div>
    )
}