import { callApi } from "./service";
const service = "/api/Users";


const get =async ()=> {
    return callApi(`${service}`, "GET");
}

const deleteById =async (id)=> {
    return callApi(`${service}/${id}`, "DELETE");
}

const getRolesByUserName =async (name)=> {
    return callApi(`${service}/${name}/Roles`, "GET");
}
const postRolesByUserName =async (name, rol)=> {
    return callApi(`${service}/${name}/Roles?role=${rol}`, "POST");
}

const deleteRolesByUserName =async (name, rol)=> {
    return callApi(`/${name}/Roles?role=${rol}`, "DELETE");
}

export const usersService = {
    get,
    deleteById,
    getRolesByUserName,
    postRolesByUserName,
    deleteRolesByUserName
}