import { callApi } from "./service";

const service = "/api/auth";


const login =async (data)=> {
    return callApi(`${service}/login`, "POST", data);
}

const register =async (data)=> {
    return callApi(`${service}/register`, "POST", data);
}

export const authService = {
    login,
    register
}