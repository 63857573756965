
export const Home =()=>{
    

    return(
      <div>
          <h1>Bienvenido</h1>
      </div>
    
    )
  }


