export const Modify =({event, update})=>{


    return(
        <div>
            <form onSubmit={update}>
            {Object.keys(event).map(key =>(
                <>
                    {key}
                    <input name={key}/>
                    <br />
                </>
            ))}
            </form>
        </div>

    )


}
