import { Link } from "react-router-dom";

export const Layout = ({children})=>{
    
    return (
        <>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        Mapsound
                    </a>
                </div>
                <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                        <Link className="navbar-item" to="/Artists">
                            Artistas
                        </Link>
                        <Link className="navbar-item" to="/Events">
                            Eventos
                        </Link>
                        <Link className="navbar-item" to="/Users">
                            Usuarios
                        </Link>
                        <Link className="navbar-item" to="/Notifications">
                            Notificaciones
                        </Link>
                    </div>
                </div>
            </nav>

            <div>
                {children}
            </div>
        </>
    )
}