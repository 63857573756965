import { callApi } from "./service";
const service = "/api/Artists";


const get =async ()=> {
    return callApi(`${service}`, "GET");
}

const post =async (data)=> {
    return callApi(`${service}`, "POST", data);
}


const getById =async (id)=> {
    return callApi(`${service}/${id}`, "GET");
}

const update =async (id, data)=> {
    return callApi(`${service}/${id}`, "PUT",data);
}

const deleteById =async (id)=> {
    return callApi(`${service}/${id}`, "DELETE");
}

export const artistsService = {
    get,
    post,
    getById,
    update,
    deleteById
}