import { useForm } from "react-hook-form";
import { authService } from './../../_services/auth.service';
import { useNavigate } from 'react-router-dom';

export const Login = ()=>{
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const logUser = (data)=>{
      authService.login(data)
      .then((token)=>{
        console.log(token);
        localStorage.setItem("user",JSON.stringify(token));
        navigate("/Home");
      })
  }
  return (
    <div className="container">
      <form onSubmit={handleSubmit(logUser)} className="columns">
        <div className="column is-flex is-flex-direction-column is-align-items-center">
          <h1>Iniciar sesion</h1>
          <p>Usuario</p>
          <input {...register("username",{required: true })}/>
          <br />
          {errors.username&& "Campo requerido"}
          <br />
          <p>Contraseña</p>
          <input {...register("password",{required: true})} type="password"/>
          <br />
          {errors.password&& "Campo requerido"}
          <br />
          <button type="submit">Iniciar Sesión</button>
        </div>
      </form>
    </div>
  );
}