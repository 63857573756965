import { useEffect, useState } from 'react';
import { usersService } from '../../_services/users.service';
import { ModifyRoles } from './ModifyRoles';

export const Users = ()=>{
    const [users, setUsers] = useState([]);
    const [modifyingUserRoles, setModifyingUserRoles] = useState();
    useEffect(()=>{
        usersService.get()
        .then((data)=>{
            setUsers(data);
        })
    },[]);
    return (
        <div>
        <h1>Usuarios</h1>
        <div>
        <table  className="table">
            <thead>
                <tr>
                    <th>
                        Nombre de usuario
                    </th>
                    <th>
                        Email
                    </th>
                    <th>
                      Puede postear
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
            </thead>
          <tbody>
            {users.map((user,index)=>
              <tr key={index}>
                <td>{user.userName}</td>
                <td>{user.email}</td>
                <td>{user.canPost}</td>
                <td>
                    <button onClick={()=>setModifyingUserRoles(user)}>Roles</button>
                    <button>Modificar</button>
                    <button>Eliminar</button>
                </td>
              </tr>
              )
              
            }
            
          </tbody>
        </table>
        </div>
        {modifyingUserRoles&& <ModifyRoles user={modifyingUserRoles} close={()=>setModifyingUserRoles(null)} />}
      </div>
    )
}