import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import "bulma/css/bulma.min.css";
import "./App.css";
import { Login } from './_pages/Login/Login';
import { Home } from './_pages/Home/Home';
import { Events } from './_pages/Events/Events';
import { Artists } from './_pages/Artists/Artists';
import { Users } from './_pages/Users/Users';
import { Layout } from './_Components/Layout';
import { Notifications } from './_pages/Notifications/Notifications';
export const App = ()=>{

  return (
    <BrowserRouter>
      <Routes>
        <Route path="Login" element={<Login/>} />
        <Route path="Home" element={<Layout children={<Home/>}/>} />
        <Route path="Events" element={<Layout children={<Events/>}/>}  />
        <Route path="Artists" element={<Layout children={<Artists/>}/>} />
        <Route path="Users" element={<Layout children={<Users/>}/>} />
        <Route path="Notifications" element={<Layout children={<Notifications/>}/>} />
        
        <Route
            path="/"
            element={<Navigate to="/Login" replace/>}
        />
      </Routes>
        
    </BrowserRouter>
  );
}