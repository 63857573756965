import { header } from './../_helpers/headerHttps';

/**
 * Comunicación con la API
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} contentType
 */
export async function callApi(url, method, body, contentType) {
  let request = {url: url, method: method, body: body, contentType: contentType};
  let user = JSON.parse(localStorage.getItem("user"));
  if( url !== "/api/auth/login" && !user){
    logout();
    return Promise.reject();
  }

  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method,
    headers: header(user, contentType),
    body: JSON.stringify(body),
  })
    .then((response) => {
      return handleResponse(response, request);
    })
    .catch(handleError);
}

// Manejo de Response
function handleResponse(response, request) {
  return response.text()
    .then((text) => {
      let data;

      // Parseo de la informacion devuelta por la api
      try {
        data = text && JSON.parse(text);
      } catch (e) {
        data = text;
      }

      // En caso de error se extrae el mensaje para mostrarlo
      // Se devuelve el mensaje de error enviado por la api
      if (!response.ok) {
        const error = [];
        error["msg"] = !data ? {msg: response.statusText} : data ?? {msg: response.statusText};
        error["code"] = response.status;
        error["request"] = request;
        return Promise.reject(error);
      }
      return data;
    });
}

// Manejo de errores
function handleError(error) {
  if (error.code === 401) {
    logout();
    return Promise.reject(error);
    // return userService.refreshToken().then((isOK) => {
    //   if (isOK) {
    //     return callApi(error.request.url, error.request.method, error.request.body, error.request.contentType);
    //   }
    // });
  } else {
    // Valida si no se le pega a la api
    if (error.message === "Failed to fetch") {
      error.message = "El servicio no se encuentra disponible.";
    }
    return Promise.reject(error);
  }
}

export function logout() {
  localStorage.removeItem("user");
  window.location.assign(`${process.env.REACT_APP_WEB_URL}/Login`);
}
