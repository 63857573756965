import { callApi } from "./service";
const service = "/api/PushNotification";


const post =async (data)=> {
    return callApi(`${service}`, "POST", data);
}



export const notificationsService = {
    post,
}