import { useCallback, useEffect, useState } from "react"
import { usersService } from "../../_services/users.service"

export const ModifyRoles = ({user, close})=>{
    const [userRoles, setUserRoles] = useState([]);
    const roles = ["Manager", "Admin"]
    
    const loadUserRoles = useCallback(()=>{
        usersService.getRolesByUserName(user.userName)
        .then(setUserRoles);
    }, [user]);
    
    const postRole = (rol) =>{
        usersService.postRolesByUserName(user.userName, rol)
        .then(()=>{
            alert("rol agregado");
            loadUserRoles();
        });
    };
    
    const deleteRole = (rol) =>{
        usersService.deleteRolesByUserName(user.userName, rol)
        .then(()=>{
            alert("rol eliminado");
            loadUserRoles();
        });
    };

    useEffect(()=>{
        loadUserRoles();
    },[loadUserRoles]);

    return(
        <div className="modal is-active">
            <div className="modal-background" onClick={close}></div>
            <div className="modal-content box">
                <p>Roles</p>
                <ul className="ml-4">
                    {userRoles.map((rol, index)=>
                        <li key={index}>
                            * {rol} <button className="ml-4" onClick={()=>deleteRole(rol)}>Quitar rol</button>
                        </li>
                    )}
                </ul>
                
                <p>
                    Agregar Roles
                </p>
                <ul className="ml-4">
                    {roles.filter((rol)=>!userRoles.includes(rol)).map((rol, index)=>
                        <li key={index}>
                            * {rol} <button className="ml-4" onClick={()=>postRole(rol)}>Agregar rol</button>
                        </li>
                    )}
                </ul>

                <button onClick={close}>Cerrar</button>
                <button>Aceptar</button>
                <button className="modal-close is-large" onClick={close}></button>
            </div>

        </div>
    )
}