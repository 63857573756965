import { useForm, useFieldArray} from "react-hook-form";
import { notificationsService } from '../../_services/notifications.service';


export const NotificationForm = ({close})=>{
    const { register, control, handleSubmit, formState: { errors } } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "actions", // unique name for your Field Array
      });
    const create = (data)=>{
        data.vibrate= [0];
        data.data = {};

        notificationsService.post(data)
        .then((result)=>{
          console.log(result);
          alert("notificación enviada");
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    
    return(
        <div>
            <button onClick={close}>Cerrar</button>
            <p>Crear notificacion</p>

            <form onSubmit={handleSubmit(create)}>
                <p>Titulo</p>
                <input {...register("title",{required: true })}/>
                {errors.title&& "Campo requerido"}
                <p>Texto</p>
                <input {...register("body",{required: true })}/>
                {errors.body&& "Campo requerido"}
                
                <p>Icono (link al icono) </p>
                <input {...register("icon",{required: true })}/>
                {errors.body&& "Campo requerido"}

                <p>Botones</p>
                {fields.map((field, index) => (
                    <fieldset key={field.id}>
                        Accion (link a donde lleva)
                        <br />
                        <input
                            {...register(`actions.${index}.action`,{required: true })} 
                        />
                        <br />
                        {errors.actions?.[index]?.action&& "Campo requerido"}
                        <br />
                        Nombre
                        <br />
                        <input
                            {...register(`actions.${index}.title`,{required: true })} 
                        />
                        <br />
                        {errors.actions?.[index]?.title&& "Campo requerido"}
                        <button onClick={() => remove(index)}>Eliminar</button>
                    </fieldset>
                ))}
                <button type="button"
                    onClick={() => {
                    append({});
                }}>
                    Agregar boton
                </button>
                <button type="button"
                    onClick={() => {
                    console.log(errors);
                }}>
                    test
                </button>
                <input type="submit" />
            </form>
            
        </div>
    )
}