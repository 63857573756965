import { useEffect, useState } from 'react';
import { eventsService } from '../../_services/events.service';
import { Modify } from '../../_Components/Modify/Modify';

export const Events = ()=>{
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState({});
    useEffect(()=>{
      eventsService.get()
      .then((data)=>{
        setEvents(data);
      })
    },[]);
    return (
        <div>
        <h1>Eventos</h1>
        <div>
        <table className="table">
            <thead>
                <tr>
                    <th>
                        ID
                    </th>
                    <th>
                        Artista
                    </th>
                    <th>
                        Fecha inicio
                    </th>
                    <th>
                        Fecha Fin
                    </th>
                    <th>
                        Entradas agotadas
                    </th>
                    <th>
                        Lugar
                    </th>
                    <th>Provincia</th>
                    <th>
                        Subscripciones
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
            </thead>
          <tbody>
            {events.map((todo,index)=>
             
              <tr key={todo.id}>
                <td>{todo.id}</td>
                <td>{todo.artist.name}</td>
                <td>{todo.date}</td>
                <td>{todo.endDate}</td>
                <td>{todo.isSoldOut ? "Si" : "No"}</td>
                <td>{todo.place}</td>
                <td>{todo.state.name}</td>
                <td>{todo.subscriptionsCount}</td>
                <td><button onClick={()=>setSelectedEvent(todo)} >Modificar</button></td>
              </tr>
              )
              
            }
            
          </tbody>
        </table>
        </div>
        <Modify event={selectedEvent} update={eventsService.update}></Modify>

      </div>
    )
}